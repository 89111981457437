<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="row">
      <div class="col-md-10">
         <h2 class="titulo-links-escolha">Relatórios</h2>
      </div>
      <div class="col-md-2" v-if="turmaSelecionada != '' && disciplinaFoiSelecionada">
        <pm-Button  type="button" @click="escolherTurmaAtiva"
          icon="pi pi-angle-left"
          label="Escolher outra turma" class="p-button-sm p-button-warning btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div v-if="(turmaSelecionada == '' || !disciplinaFoiSelecionada) && !carregandoPagina">
        <pm-Card>
          <template #content>
            <div class="p-fluid formgrid grid">
              <div class=" field col-12 md:col-6">
                <label for="disciplina">Selecionar uma turma para verificar o relatório</label>
                <pm-Dropdown  @change="buscarRelatorios" v-model="turmaSelecionada"  optionLabel="nomeCompleto" :options="turmasDoProfessor"  placeholder="Selecione uma turma" />
              </div>
              <div class=" field col-12 md:col-6" v-if="turmaSelecionada">
                <label for="disciplina">Selecione uma disciplina</label>
                <pm-Dropdown  @change="buscarRelatorioDaDisciplinaSelecionadaParaFundamentalDois" v-model="disciplinaSelecionada"  optionLabel="nome" :options="disciplinasProfessorALL"  placeholder="Selecione uma disciplina" />
              </div>
            </div>
          </template>
          <template #footer>
            <pm-Button  type="button" @click="this.$router.push({name: 'painel-professor'});" icon="pi pi-angle-left"
                  label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color"
              >
            </pm-Button>
          </template>
        </pm-Card>
    </div>

    <div v-if="turmaSelecionada != '' && disciplinaFoiSelecionada && !carregandoPagina">
      <div>
        <h2 class="titulo__relatorio">Informações gerais da turma <u>{{turmaSelecionada.nome}}</u> para disciplina de <u>{{disciplinaSelecionada.nome}}</u></h2>
      </div>
      <div  style="background-color:#dec27c;margin-top: 16px;">
        <div class="row">
          <div class="col-md-4">
            <h6 class="sub__titulo1">Total de alunos na turma: {{turmaSelecionadaInformacoesRelatorio.alunos}}</h6>
          </div>
          <div class="col-md-8">
            <h6 class="sub__titulo2">Gráfico de Informações por quantitativo</h6>
          </div>
        </div>
      </div>
      <br>
      <div class="row" >
        <div class="col-md-3">
          <div class="pm-card">
            <div class="col-md-12">
              <h5 style="margin-bottom:23px;text-align: center;">{{chartData0.datasets[0].label}}</h5>
              <va-chart  v-if="okChart0" class="chart chart--donut" :data="chartData0" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="pm-card">
            <div class="col-md-12">
              <h5 style="margin-bottom:23px;text-align: center;">{{chartData1.datasets[0].label}}</h5>
              <va-chart  v-if="okChart1" class="chart chart--donut" :data="chartData1" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="pm-card">
            <div class="row">
              <h5 style="margin-bottom:23px;text-align: center;">RENDIMENTO POR DISCIPLINA</h5>
              <div  class="col-md-3">
                <h5 style="margin-bottom:23px;text-align: center;">SATISFATÓRIO</h5>
                <va-chart  v-if="okChart2" class="chart chart--donut" :data="chartData2" type="donut"/>
                <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
              </div>
              <div  class="col-md-3">
                <h5 style="margin-bottom:23px;text-align: center;">NÃO SATISFATÓRIO</h5>
                <va-chart  v-if="okChart3" class="chart chart--donut" :data="chartData3" type="donut"/>
                <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
              </div>
              <div  class="col-md-3">
                <h5 style="margin-bottom:23px;text-align: center;">EVADIDO</h5>
                <va-chart  v-if="okChart4" class="chart chart--donut" :data="chartData4" type="donut"/>
                <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
              </div>
              <div  class="col-md-3">
                <h5 style="margin-bottom:23px;text-align: center;">TRANSFERIDO</h5>
                <va-chart  v-if="okChart5" class="chart chart--donut" :data="chartData5" type="donut"/>
                <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="turmaSelecionada != '' && disciplinaFoiSelecionada && !carregandoPagina">
      <div  style="background-color:#dec27c;margin-top: 16px;">
        <div class="row">
          <div class="col-md-12" style="text-align:center;">
            <h6 class="sub__titulo3">rendimento por disciplina em porcentagem (%)</h6>
          </div>
        </div>

      </div>
      <br>
      <div class="col-md-12">
        <div class="pm-card">
          <div class="row">
            <h5 style="margin-bottom:23px;text-align: center;">RENDIMENTO POR DISCIPLINA (%)</h5>
            <div  class="col-md-3">
              <h5 style="margin-bottom:23px;text-align: center;">SATISFATÓRIO</h5>
              <va-chart  v-if="okChart6" class="chart chart--donut" :data="chartData6" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
            <div  class="col-md-3">
              <h5 style="margin-bottom:23px;text-align: center;">NÃO SATISFATÓRIO</h5>
              <va-chart  v-if="okChart7" class="chart chart--donut" :data="chartData7" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
            <div  class="col-md-3">
              <h5 style="margin-bottom:23px;text-align: center;">EVADIDO</h5>
              <va-chart  v-if="okChart8" class="chart chart--donut" :data="chartData8" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
            <div  class="col-md-3">
              <h5 style="margin-bottom:23px;text-align: center;">TRANSFERIDO</h5>
              <va-chart  v-if="okChart9" class="chart chart--donut" :data="chartData9" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-if="turmaSelecionada != '' && disciplinaFoiSelecionada && !carregandoPagina">
      <div  style="background-color:#dec27c;margin-top: 16px;">
        <div class="row">
          <div class="col-md-12" style="text-align:center;">
            <h6 class="sub__titulo3">presenças</h6>
          </div>
        </div>

      </div>
      <br>
      <div class="col-md-12">
        <div class="pm-card">
          <div class="row">
            <div  class="col-md-6">
              <h5 style="margin-bottom:23px;text-align: center;">PRESENÇA POR QUANTITATIVO DE ALUNOS</h5>
              <va-chart  v-if="okChart10" class="chart chart--donut" :data="chartData10" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
            <div  class="col-md-6">
              <h5 style="margin-bottom:23px;text-align: center;">PERCENTUAL DE PRESENÇA</h5>
              <va-chart  v-if="okChart11" class="chart chart--donut" :data="chartData11" type="donut"/>
              <h1 v-else style="text-align: center;color:#ffc107; padding-top: 120px;" >SEM DADOS PARA EXIBIÇÃO!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
          <pm-ProgressSpinner />
    </div>

  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Relatorios } from "@/class/relatorios";
import { Turma } from "@/class/turma";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import VaChart from '@/components/va-charts/VaChart.vue'
import { useGlobalConfig } from 'vuestic-ui'
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";

export default defineComponent ({
  components: { VaChart},
  data(){
    return{
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Relatórios'},
      ],
      carregandoPagina : false,
      turmaSelecionada:'',
      turmasDoProfessor:[],
      turmaSelecionadaInformacoesRelatorio:[],
      chartData0:null,
      chartData1:null,
      chartData2:null,
      chartData3:null,
      chartData4:null,
      chartData5:null,
      chartData6:null,
      chartData7:null,
      chartData8:null,
      chartData9:null,
      chartData10:null,
      chartData11:null,
      aTurmaEhFundamental2:false,
      disciplinaFoiSelecionada:false,
      professor_id:0,
      disciplinasProfessorALL:[],
      disciplinaSelecionada:'',
      ehDoTipoEja:false,
      okChart0:false,
      okChart1:false,
      okChart2:false,
      okChart3:false,
      okChart4:false,
      okChart5:false,
      okChart6:false,
      okChart7:false,
      okChart8:false,
      okChart9:false,
      okChart10:false,
      okChart11:false,
    }

  },

  methods: {
    //Presencas e percentual de presenca dos alunos
    gerarGraficosPresenca(infoRelatorioDisciplina) {
      this.chartData10 = {
        labels: [],
        datasets: [{
          label: [],
          backgroundColor: [],
          data: [],
        }],
      };
      this.chartData11 = {
        labels: [],
        datasets: [{
          label: [],
          backgroundColor: [],
          data: [],
        }],
      };
      //presenca dos alunos por disciplina
      this.chartData10.labels =  ['MAIOR OU IGUAL Á 75%', 'MENOR QUE 75%', 'TRANSFERÊNCIA', 'EVADIDO'];
      this.chartData10.datasets[0].backgroundColor.push("#" + this.gerarUmaCorAleatoria(), "#" + this.gerarUmaCorAleatoria(), "#" + this.gerarUmaCorAleatoria(), "#" + this.gerarUmaCorAleatoria());
      this.chartData10.datasets[0].data.push(infoRelatorioDisciplina.presenca_total_aprovados);
      this.chartData10.datasets[0].data.push(infoRelatorioDisciplina.presenca_total_reprovados);
      this.chartData10.datasets[0].data.push(infoRelatorioDisciplina.presenca_total_transferidos);
      this.chartData10.datasets[0].data.push(infoRelatorioDisciplina.presenca_total_evadidos);

      this.chartData11.datasets[0].backgroundColor = this.chartData10.datasets[0].backgroundColor;

      //presenca dos alunos por disciplina em porcentagem
      this.chartData11.labels =  ['MAIOR OU IGUAL Á 75%', 'MENOR QUE 75%', 'TRANSFERÊNCIA', 'EVADIDO'];
      let totalPresenca = infoRelatorioDisciplina.presenca_total_aprovados + infoRelatorioDisciplina.presenca_total_reprovados + infoRelatorioDisciplina.presenca_total_transferidos + infoRelatorioDisciplina.presenca_total_evadidos;
      this.chartData11.datasets[0].data.push(parseInt(infoRelatorioDisciplina.presenca_total_aprovados / totalPresenca * 100));
      this.chartData11.datasets[0].data.push(parseInt(infoRelatorioDisciplina.presenca_total_reprovados / totalPresenca * 100));
      this.chartData11.datasets[0].data.push(parseInt(infoRelatorioDisciplina.presenca_total_transferidos / totalPresenca * 100));
      this.chartData11.datasets[0].data.push(parseInt(infoRelatorioDisciplina.presenca_total_evadidos / totalPresenca * 100));

      this.okChart10 = false;
      this.okChart11 = false;

      for (const el of this.chartData10.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart10 = true;
      }
      this.okChart11 = false;
      for (const el of this.chartData11.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart11 = true;
      }
    },
    gerarTransferidoPorcentagem(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);

      this.chartData9 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart9 = false;
      for (const el of this.chartData9.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart9 = true;
      }
    },
    gerarEvadidoPorcentagem(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);

      this.chartData8 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart8 = false;
      for (const el of this.chartData8.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart8 = true;
      }
    },
    gerarNaoSatisfatorioPorcentagem(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);

      this.chartData7 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart7 = false;
      for (const el of this.chartData7.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart7 = true;
      }
    },
    gerarSatisfatorioPorcentagem(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);

      this.chartData6 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart6 = false;
      for (const el of this.chartData6.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart6 = true;
      }
    },
    gerarTransferido(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);

      this.chartData5 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart5 = false;
      for (const el of this.chartData5.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart5 = true;
      }
    },
    gerarEvadido(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);
      this.chartData4 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart4 = false;
      for (const el of this.chartData4.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart4 = true;
      }
    },
    gerarNaoSatisfatorio(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);
      this.chartData3 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart3 = false;
      for (const el of this.chartData3.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart3 = true;
      }
    },
    gerarSatisfatorio(info) {
      let dados = [];
      dados.push(info.p1, info.p2);
      //Se nao for do tipo EJA, add mais dois periodos
      if (!this.ehDoTipoEja)
        dados.push(info.p3, info.p4);
      this.chartData2 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad'],
          data: dados,
        }],
      };
      this.okChart2 = false;
      for (const el of this.chartData2.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart2 = true;
      }
    },
    gerarMediaGlobal(info) {
      this.chartData1 = {
        labels: ['Média Global'],
        datasets: [{
          label: "MÉDIA DA TURMA",
          backgroundColor: ['#ffd43a'],
          data: [info],
        }],
      };
      this.okChart1 = false;
      for (const el of this.chartData1.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart1 = true;
      }
    },
    gerarMediaDisciplina(infoRelatorioDisciplina) {
      let p1 = Math.round(infoRelatorioDisciplina.media_disciplina.p1);
      let p2 = Math.round(infoRelatorioDisciplina.media_disciplina.p2);
      let p3 = Math.round(infoRelatorioDisciplina.media_disciplina.p3);
      let p4 = Math.round(infoRelatorioDisciplina.media_disciplina.p4);
      let total_media = Math.round(infoRelatorioDisciplina.media_disciplina.total_media);

      this.chartData0 = {
        labels: [ '1º Periodo' , '2º Periodo', '3º Periodo', '4º Periodo', 'Total Média'],
        datasets: [{
          label: "MÉDIA POR DISCIPLINA",
          backgroundColor: ['#ffd43a', '#154ec1','#18ad46', '#1876ad', '#0b5a87'],
          data: [p1, p2, p3, p4, total_media],
        }],
      };
      this.okChart0 = false;
      for (const el of this.chartData0.datasets[0].data) {
        if (el != 0 && el != null)
          this.okChart0 = true;
      }

    },
    buscarRelatorioDaDisciplinaSelecionadaParaFundamentalDois() {
      this.disciplinaFoiSelecionada = false;
      let infoRelatorioDisciplina = [];
      for (const el of this.turmaSelecionadaInformacoesRelatorio.disciplinas) {
        if (el.disciplina_id == this.disciplinaSelecionada.id) {
          infoRelatorioDisciplina = el;
          break;
        }
      }
      this.carregandoPagina = true;
      //media por disciplina
      this.gerarMediaDisciplina(infoRelatorioDisciplina);
      //media global
      this.gerarMediaGlobal(this.turmaSelecionadaInformacoesRelatorio.media_global_turma);
      //satisfatorio
      this.gerarSatisfatorio(infoRelatorioDisciplina.rendimento_disciplina_satisfatorio);
      //nao satisfatorio
      this.gerarNaoSatisfatorio(infoRelatorioDisciplina.rendimento_disciplina_nao_satisfatorio);
      //evadido
      this.gerarEvadido(infoRelatorioDisciplina.rendimento_disciplina_evadidos);
      //transferido
      this.gerarTransferido(infoRelatorioDisciplina.rendimento_disciplina_transferidos);

      //satisfatorio %
      this.gerarSatisfatorioPorcentagem(infoRelatorioDisciplina.rendimento_disciplina_satisfatorio_porcento);
      //nao satisfatorio %
      this.gerarNaoSatisfatorioPorcentagem(infoRelatorioDisciplina.rendimento_disciplina_nao_satisfatorio_porcento);
      //evadido %
      this.gerarEvadidoPorcentagem(infoRelatorioDisciplina.rendimento_disciplina_satisfatorio_porcento_evadidos);
      //transferido %
      this.gerarTransferidoPorcentagem(infoRelatorioDisciplina.rendimento_disciplina_satisfatorio_porcento_transferidos);
      //presenca
      this.gerarGraficosPresenca(infoRelatorioDisciplina);
      this.carregandoPagina = false;
      this.disciplinaFoiSelecionada = true;
    },
    gerarUmaCorAleatoria() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    escolherTurmaAtiva() {
      this.turmaSelecionada = '';
      this.disciplinaFoiSelecionada = false;
      this.disciplinaSelecionada = '';
    },
    async verificarSeATurmaEhFundamentalDois() {
      this.aTurmaEhFundamental2 = false;
      this.disciplinaFoiSelecionada = false;
      let data = await SegmentoEscolar.obtemUm(this.turmaSelecionadaInformacoesRelatorio.segmento_id);
      let segmentInfo = data.data;
      //Do tipo educacao infantil
      if (segmentInfo.tipo == 0) {
        //await this.verificarDisciplinaEducacoInfantil();
        this.aTurmaEhFundamental2 = false;
        this.disciplinaFoiSelecionada = true; //Fundamental I ou creche nao precisa selecionar a disciplina

      }
      //Do tipo fundamental 1 ou ERN
      else if (segmentInfo.tipo == 1) {
        this.aTurmaEhFundamental2 = false;
        this.disciplinaFoiSelecionada = true; //Fundamental I ou creche nao precisa selecionar a disciplina
      }
      //Do tipo fundamental 2
      else if (segmentInfo.tipo == 2) {
        this.aTurmaEhFundamental2 = true;
      }
      //Do tipo EJA
      else if (segmentInfo.tipo == 3) {
        this.ehDoTipoEja = true;
        //Verificar de qual tipo é o EJA
        let data1 = await SeriesEscolar.obtemUm(this.turmaSelecionadaInformacoesRelatorio.serie_id);
        let periodoInfo = data1.data;
        //Se for do tipo 1, a turma terá apenas um professor
        if (periodoInfo.tipo == 1) {
          this.aTurmaEhFundamental2 = false;
          this.disciplinaFoiSelecionada = true; //Fundamental I ou creche nao precisa selecionar a disciplina
        }
        else {
          this.aTurmaEhFundamental2 = true;
        }
      }
      this.disciplinaFoiSelecionada = false;
    },
    async buscarDisciplinasDoProfessorNaTurma() {
      let info = {
        turma_id: this.turmaSelecionadaInformacoesRelatorio.id,
        professor_id: this.professor_id
      }
      let data = await Turma.obterTodasDisciplinaDeUmProfessorEmUmaTurma(info);

      this.disciplinasProfessorALL = [];
      for (const el of data.data) {
        this.disciplinasProfessorALL.push(el.disciplina);
      }
    },
    async buscarRelatorios() {
      this.carregandoPagina = true;
      let data = await Relatorios.obtemTodosProfessores();
      //Pegar informações da turma selecionada
      for (const el of data.data) {
        if (el.id == this.turmaSelecionada.id) {
          this.turmaSelecionadaInformacoesRelatorio = el;
          break;
        }
      }
      this.carregandoPagina = true;
      await this.verificarSeATurmaEhFundamentalDois();
      this.buscarDisciplinasDoProfessorNaTurma();
      this.carregandoPagina = false;
    },
    async listarTurmasDoProfessor() {
      try{
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);
        const calendario = await Calendario.calendarioAtual();
        this.professor_id =  whoiam.data.usuario.id;
        let novo = {
          professor: this.professor_id,
          ano: calendario.data.ano
        }
        let data = await Turma.obterTodasTurmasDoProfessor(novo);
        this.turmasDoProfessor = [];
        for (const el of data.data) {
          el.turma[0].nomeCompleto = el.turma[0].nome + ' - ' + el.turma[0].turno + ' - ' + el.turma[0].serie  + ' - ' + el.turma[0].segmento  + ' - ' + el.turma[0].escola;
          this.turmasDoProfessor.push(el.turma[0]);
        }
      }catch(e){
          console.log(e);
      }
    }
  },
  async beforeMount() {
    await this.listarTurmasDoProfessor();
  }

});
</script>

<style scoped>
  .chart {
    height: 400px;
  }
  .titulo__relatorio {
    text-transform: uppercase;
    color: #fff;
    background-color: #0d89ec;
    text-align: center;
    font-weight: bold;
  }
  .sub__titulo1 {
    font-size: 20px;
    padding-left: 20px;
    padding-top: 5px;
    text-transform: uppercase;
	  font-weight: bold;
  }
  .sub__titulo2 {
    font-size: 20px;
    padding-left: 20px;
    padding-top: 5px;
    text-transform: uppercase;
	  font-weight: bold;
  }
   .sub__titulo3 {
    font-size: 20px;
    padding-left: 20px;
    padding-top: 5px;
    text-transform: uppercase;
	  font-weight: bold;
  }
</style>
